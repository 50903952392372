import { Stack } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

// import { DASHBOARD } from '@root/constants/navigation';

const TableHeader = (props) => {
  const { title, extra, onBack, style, ...extraProps } = props;
  const navigate = useNavigate();

  const goBack = () => navigate(-1);

  return (
    <Stack direction="horizontal" gap={3} style={style} {...extraProps}>
      {onBack && (
        <button
          onClick={goBack}
          className="btn btn-rounded-circle btn-white justify-content-center"
          style={{ paddingTop: '0.375rem', paddingBottom: '0.375rem' }}>
          <span className="fe fe-arrow-left" style={{ fontSize: 18 }}></span>
        </button>
      )}
      {title && (
        <div className="d-flex align-items-center">
          <h2 className="text-truncate mb-0" style={{ minWidth: 96, maxWidth: 230 }}>
            {title}
          </h2>
        </div>
      )}
      {extra && extra}
    </Stack>
  );
};

export default TableHeader;
