import { NavLink as RouterLink, matchPath, useLocation } from 'react-router-dom';
import { Col, Stack } from 'react-bootstrap';

import {
  SETTINGS_GENERAL,
  BOXSIZES,
  PREP_REQUIREMENTS,
  REAZONS_ARCHIVE,
} from '@root/constants/navigation';
import cls from '@root/utils/cls';
import { useResponsive } from '@root/hooks';
import { IconButton } from '@root/ui-component/dataDisplay';

interface SideNavbarProps {
  hasSidenav?: boolean;
}

interface NavItemProps {
  url: string;
  title: string;
  icon: string;
}

const NavItem = (props: NavItemProps) => {
  const { url, title, icon } = props;
  const location = useLocation();
  const isActive = url ? !!matchPath({ path: url, end: false }, location.pathname) : false;

  return (
    <RouterLink to={url} role="button">
      <IconButton
        variant={isActive ? 'primary' : 'white'}
        startIcon={icon}
        className="d-flex align-items-center w-100">
        <span className="text-nowrap">{title}</span>
      </IconButton>
    </RouterLink>
  );
};

const SideNavbar = (props: SideNavbarProps) => {
  const { hasSidenav } = props;
  const { isDesktop } = useResponsive();

  return (
    <>
      {hasSidenav && (
        <Col
          md="auto"
          className={cls('p-0', isDesktop ? 'border-end h-100' : 'border-bottom h-auto')}
          style={{ width: isDesktop ? '15rem' : 'auto' }}>
          <Stack
            direction={isDesktop ? 'vertical' : 'horizontal'}
            gap={3}
            className="py-3 px-3"
            style={{ overflowX: 'auto', overflowY: 'hidden' }}>
            <NavItem title="General" icon="settings" url={SETTINGS_GENERAL} />
            <NavItem title="Box Sizes" icon="box" url={BOXSIZES} />
            <NavItem title="Prep Requirements" icon="package" url={PREP_REQUIREMENTS} />
            <NavItem title="Reasons to Archive" icon="archive" url={REAZONS_ARCHIVE} />
          </Stack>
        </Col>
      )}
    </>
  );
};

export default SideNavbar;
