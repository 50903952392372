import createAuthRefreshInterceptor from 'axios-auth-refresh';
import axios from 'axios';
import Swal from 'sweetalert2';

import { authService } from '@root/services';
import types from '@root/store/actions';
import store from '@root/store';

const setSession = (token, refreshToken) => {
  if (token && refreshToken) {
    localStorage.setItem('token', token);
    localStorage.setItem('token-refresh', refreshToken);
    localStorage.setItem('token-init-date', new Date().getTime());
  } else {
    localStorage.removeItem('token');
    localStorage.removeItem('token-refresh');
    localStorage.removeItem('token-init-date');
  }
};

const axiosServices = axios.create({
  baseURL: process.env.REACT_APP_ARIA_API,
  timeout: 1200000,
});

axiosServices.interceptors.request.use((config) => {
  // const { token } = store.getState().auth;
  const token = localStorage.getItem('token');
  // console.debug('[Request Interceptor]', config.baseURL + config.url, JSON.stringify(token));
  config.headers['Authorization'] = token ? `Bearer ${token}` : '';
  config.headers['Content-Type'] = 'application/json';
  return config;
});

// interceptor for http
axiosServices.interceptors.response.use(
  (res) => {
    // console.debug('[Response]', res.config.baseURL + res.config.url, res.status, res.data);
    return Promise.resolve(res);
  },
  async (err) => {
    // const resStatus = err?.response?.status;
    // const resData = err?.response && err?.response?.data;
    // console.debug('[Response]', err.config.baseURL + err.config.url, resStatus, resData);
    return Promise.reject(err);
  },
);

const refreshAuthLogic = async (failedRequest) => {
  const { user, refreshToken } = store.getState().auth;
  if (refreshToken !== null) {
    return authService
      .tokenRefresh({ refresh: refreshToken })
      .then((data) => {
        setSession(data.access, refreshToken);
        failedRequest.response.config.headers.Authorization = 'Bearer ' + data.access;
        store.dispatch({
          type: types.AUTH_LOGIN,
          payload: { user, token: data?.access, refreshToken },
        });
        return Promise.resolve();
      })
      .catch((e) => {
        console.debug('[Renew Token Error]', e.baseURL + e.url, e);
        setSession();
        delete failedRequest.response.config.headers.Authorization;
        store.dispatch({ type: types.AUTH_RENEW_TOKEN });
        Swal.fire('Auth Error', e?.detail ? e?.detail : e?.message, 'error');
        return Promise.reject();
      });
  }
};

createAuthRefreshInterceptor(axiosServices, refreshAuthLogic);

export default axiosServices;
