import cls from '@root/utils/cls';
import React from 'react';

interface FlexItemProps {
  title?: string;
  value: string | number | React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
  titleWidth?: number;
  direction?: 'row' | 'column';
}

const FlexItem: React.FC<FlexItemProps> = (props) => {
  const { title, titleWidth = 90, direction = 'row', value, className, style } = props;
  return (
    <div
      className={cls(
        'd-flex border-bottom px-2 w-100',
        direction === 'row' ? 'align-items-center' : 'flex-column align-items-start py-2',
        className,
      )}
      style={{ height: 30, ...style }}>
      {title && (
        <div
          className={cls('h5 text-muted flex-shrink-0', direction === 'row' ? 'mb-0' : 'mb-1')}
          style={{ width: direction === 'row' ? titleWidth : 'auto' }}>
          {title}
        </div>
      )}
      <div className="h5 mb-0 w-100">{value}</div>
    </div>
  );
};

export default FlexItem;
